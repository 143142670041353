<template>
  <div class="dropdown main-category">
    <button
      @click.prevent="dropdownOpened = !dropdownOpened"
      class="w-100 main-category-dropdown-toggle mb-2"
      :class="{ active: !!dropdownOpened }"
      type="button"
    >
      <i :class="iconClass" class="d-flex"></i>
      <span>{{ category.vname }}</span>
    </button>
    <ul class="main-category__dropdown list-unstyled w-100" v-if="dropdownOpened">
      <li class="current-category-link" v-if="0 === category.children.length">
        <router-link
          exact
          class="category-link"
          :to="{
            name: 'analytics:category',
            params: { categoryId: category.vsourceid, page: 'goods' },
          }"
          >Открыть раздел: {{ category.vname }}</router-link
        >
      </li>
      <template v-if="!!category.children.length">
        <li
          v-for="child in category.children"
          :key="`category_${category.vsourceid}_children-${child.vsourceid}`"
        >
          <subcategory :category="child"></subcategory>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Subcategory from '@/pages/analytics/categories/menu/Subcategory.vue';
import CategoryIconsMap from '@/pages/analytics/categories/menu/CategoryIconsMap';

export default {
  components: { Subcategory },
  props: ['category', 'column'],
  data() {
    return {
      dropdownOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      openedFromStore: 'allCategoriesOpened',
    }),
    iconClass() {
      const icon = CategoryIconsMap[this.category.vsourceid] || 'etc';
      return `rigel-icon-${icon} column${this.column}`;
    },
  },
  watch: {
    openedFromStore: {
      deep: true,
      immediate: true,
      handler(v) {
        this.dropdownOpened = v;
      },
    },
  },
};
</script>
